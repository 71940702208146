import React, { Component } from "react";
import {
  Map,
  TileLayer,
  Marker,
  ZoomControl,
  LayersControl,
} from "react-leaflet";
import moment, { unix } from "moment";
import axios from "axios";
import GoogleLayer from "react-leaflet-google-layer";

import "./App.css";
import FinderLogo from "./assets/finder_logo_white.png";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

class App extends Component {
  state = {
    lat: 0,
    lng: 0,
    assetName: "",
    heading: 0,
    assetType: "",
    ignition: false,
    speed: 0,
    time: new Date(),
    zoom: 4,
    expires_at: new Date(),
  };

  from = "mobile";

  componentDidMount() {
    const urlEndpoint = window.location.pathname.split("/")[1];
    this.from = window.location.pathname.split("/")[2] || "";
    if (urlEndpoint) {
      this.fetchLiveData(urlEndpoint);
      this.fetchingInterval = setInterval(() => {
        this.fetchLiveData(urlEndpoint);
      }, 10000);
    }
  }

  fetchLiveData = (endpoint) => {
    if (!this.state.assetName) {
      this.refs.leaflet.leafletElement.setZoom(18);
    }

    axios
      .get(`${process.env.REACT_APP_FLT_BASE_API}/tracker/${endpoint}`)
      .then(({ data: response }) => {
        const { status } = response;
        if (status === "success") {
          const { data, expires_at } = response;
          const {
            asset_name,
            asset_type,
            heading,
            location,
            ignition,
            speed,
            time,
          } = data;
          const [lng, lat] = location.coordinates;
          this.setState({
            message: "",
            lat,
            lng,
            heading,
            speed,
            ignition,
            assetName: asset_name,
            assetType: asset_type,
            time,
            expires_at,
          });
        } else if (status === "failed") {
          clearInterval(this.fetchingInterval);
          const { message } = response;
          this.setState({
            assetName: "",
            message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { lat, lng, assetName, speed, time, ignition, message } = this.state;
    console.log({ key: GOOGLE_MAP_KEY });

    return (
      <div>
        <div
          className="header"
          style={{ display: this.from === "mobile" ? "none" : "block" }}
        >
          <a href="http://www.finder-lbs.com">
            <img src={FinderLogo} id="finder-logo" alt="finder-logo" />
          </a>
        </div>
        {message && (
          <div className="info">
            {message && <div className="message">{message}</div>}
          </div>
        )}

        {assetName && (
          <div className="info">
            <div className="info-box">• Vehicle Name: {assetName} </div>
            <div className="info-box">
              • Time: {moment(unix(time)).format("dddd MMM Do, h:mm A")}
            </div>
            <div className="info-box">
              • Ignition: {ignition ? "ON" : "OFF"}
            </div>
            <div className="info-box">• Speed: {speed} km/h</div>
          </div>
        )}

        <div>
          <Map
            center={[lat, lng]}
            ref="leaflet"
            maxZoom={18}
            id="map-box"
            zoomControl={false}
            useFlyTo
            bounds={[
              [26.765230565697482, 93.17504882812501],
              [22.11617714721062, 86.5667724609375],
            ]}
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer name="Google Maps Roads" checked={true}>
                <GoogleLayer
                  googleMapsLoaderConf={{ key: GOOGLE_MAP_KEY }}
                  type={"roadmap"}
                  maxZoom={22}
                  useGoogMapsLoader={false}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="OpenStreetMap">
                <TileLayer
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  maxZoom={19}
                />
              </LayersControl.BaseLayer>
            </LayersControl>
            <ZoomControl position="bottomright" />
            {assetName && <Marker position={[lat, lng]} />}
          </Map>
        </div>
      </div>
    );
  }
}

export default App;
